import "react-multi-carousel/lib/styles.css"

import Carousel from "react-multi-carousel"
import Img from "@components/common/Img"
import MediaCarouselMobile from "@components/MediaCarouselMobile.jsx"
import React from "react"
import { useMediaQuery } from "@components/common/hoc/useMediaQuery"

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
}

function CustomArrow({ onClick, ...rest }) {
  const {
    pos,
    onMove,
    carouselState: { currentSlide },
  } = rest

  return (
    <button
      className={`cursor-pointer absolute top-0 h-full w-1/3 max-w-650 focus:outline-none ${
        pos === "right" ? "right-0 green" : "left-0 blue"
      }`}
      onClick={() => !onMove && onClick()}
    />
  )
}

function ImageCarousel({ images }) {
  const activeMq = useMediaQuery()

  if (process.env.NODE_ENV !== "production") return null

  return (
    <div className="col-span-12 py-14">
      {activeMq === "sm" ? (
        <MediaCarouselMobile items={images}>
          <ul className="flex w-full space-x-14">
            <li style={{ maxWidth: "14px", minWidth: "1px" }}>&nbsp;</li>
            {images.map((image, i) => (
              <li key={i}>
                <Img
                  image={image?.image || image}
                  visibleByDefault={i < 2 || i === images.length - 1}
                />
              </li>
            ))}
            <li style={{ maxWidth: "14px", minWidth: "1px" }}>&nbsp;</li>
          </ul>
        </MediaCarouselMobile>
      ) : (
        <Carousel
          swipeable
          draggable
          ssr
          keyBoardControl
          centerMode
          infinite
          customRightArrow={<CustomArrow pos="right" />}
          customLeftArrow={<CustomArrow pos="left" />}
          itemClass="media-carousel-item"
          responsive={responsive}
        >
          {images.map((image, i) => (
            <Img key={image.id} image={image} visibleByDefault={true} />
          ))}
        </Carousel>
      )}
    </div>
  )
}

ImageCarousel.displayName = "ImageCarousel"

export default ImageCarousel
