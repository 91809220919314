import ImageCarousel from "@components/ImageCarousel"
import React from "react"
import SEO from "@components/common/SEO"
import { getPrice } from "@src/helpers"
import { graphql } from "gatsby"
import parse from "html-react-parser"
import useGeneralContext from "@components/common/hoc/useGeneralContext"
import useStoreContext from "@components/common/hoc/useStoreContext"

// add newsletter block

const Product = ({ data, pageContext }) => {
  const { isHome, setIsHome } = useGeneralContext()
  const {
    addVariantToCart,
    store: { client, adding },
  } = useStoreContext()
  const {
    title,
    description,
    descriptionHtml,
    images,
    variants,
    variants: [initialVariant],
  } = data.shopifyProduct

  const seo = {
    title: `SUNDAY - ${title}`,
    metaDesc: description,
    opengraphImage: { sourceUrl: images[0].originalSrc },
  }

  const [variant, setVariant] = React.useState(setInitialVariant(variants))
  const [price, setPrice] = React.useState(getPrice(variant?.price))
  const [quantity, setQuantity] = React.useState(1)
  const productVariant =
    client.product.helpers.variantForOptions(data.shopifyProduct, variant) ||
    variant
  const [available, setAvailable] = React.useState(
    productVariant.availableForSale
  )

  const checkAvailability = React.useCallback(
    productId => {
      // console.log({ productId })
      client.product.fetch(productId).then(fetchedProduct => {
        // console.log({ fetchedProduct })
        // this checks the currently selected variant for availability
        const result = fetchedProduct.variants?.filter(
          variant => variant.id === productVariant.shopifyId
        )

        if (result?.length > 0) {
          setAvailable(result[0].available)
        }
      })
    },
    [client.product, productVariant.shopifyId]
  )

  function setInitialVariant(variants) {
    const available = variants.filter(variant => variant.availableForSale)

    if (available.length === 0) return { ...variants[0] }

    return { ...available[0] }
  }

  function setActiveVariant(variant) {
    if (variant.price !== price) {
      setPrice(getPrice(variant.price))
    }

    return setVariant({ ...variant })
  }

  function handleAddToCart() {
    return addVariantToCart(productVariant.shopifyId, quantity)
  }

  React.useEffect(() => {
    checkAvailability(data.shopifyProduct.shopifyId)
  }, [productVariant, checkAvailability, data.shopifyProduct.shopifyId])

  React.useEffect(() => {
    if (isHome) {
      setIsHome(!isHome)
    }
  }, [])

  return (
    <React.Fragment>
      <SEO seo={seo} />
      <section className="col-span-12">
        <ImageCarousel images={images} />
        <h1 className="mt-40 mb-20 uppercase text-center text-header md:mt-60">
          {title}
          <span className="block pt-20">
            {variants.length > 1 && (
              <ul className="flex justify-center space-x-10 mt-40 mb-30">
                {variants.map(item => (
                  <li key={item.id} className="mb-10">
                    <button
                      type="button"
                      disabled={!item.availableForSale}
                      onClick={() => setActiveVariant(item)}
                      className={`border-2 border-black rounded-full uppercase pt-6 px-12 ml-12 leading-none focus:outline-none disabled:opacity-70 disabled:cursor-not-allowed ${
                        variant.id === item.id ? "bg-black text-offWhite" : ""
                      }`}
                    >
                      {item.title}
                    </button>
                  </li>
                ))}
              </ul>
            )}
            {price}
            <button
              type="button"
              disabled={!available || adding}
              onClick={handleAddToCart}
              className="border-2 border-black rounded-full uppercase pt-6 px-12 ml-12 leading-none focus:outline-none disabled:opacity-70 disabled:cursor-not-allowed"
            >
              {!available ? "Out of stock" : "Buy"}
            </button>
          </span>
        </h1>
      </section>
      <section className="col-span-12 u-aspect relative md:col-span-12 text-black bg-orange font-futura text-medium u-aspect-32-13 p-10">
        {parse(descriptionHtml)}
      </section>
    </React.Fragment>
  )
}

export const data = graphql`
  query ProductQuery($id: String!) {
    shopifyProduct(id: { eq: $id }) {
      title
      description
      handle
      id
      shopifyId
      images {
        id
        originalSrc
      }
      variants {
        requiresShipping
        id
        title
        price
        availableForSale
        shopifyId
        selectedOptions {
          name
          value
        }
      }
      descriptionHtml
    }
  }
`

export default Product
